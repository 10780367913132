






































































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { IContact, IPaginate, PayloadState } from '@/types/types';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { namespace } from 'vuex-class';
import { debounce } from 'lodash';

const contactModule = namespace('contact');
extend('required', {
  ...required,
  message: 'Field is required',
});

@Component({
  name: 'AddPaymentMethod',
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class GetLink extends mixins(WidgetMixins) {
  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) contacts!: Array<IContact>;
  @Prop({ default: () => [] }) assignedContacts!: number[];

  @contactModule.Getter('getPagination') paginate!: Pick<
    IPaginate,
    'page' | 'total' | 'itemsPerPage' | 'pages'
  > & { limit: number };

  selected: IContact[] | number[] = [];
  headers: Array<{ text: string; value: string; align?: string }> = [
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Phone Number',
      value: 'phone',
    },
  ];

  total = 0;
  search = '';

  options: IPaginate = {
    page: 1,
    total: 0,
    pages: 1,
    itemsPerPage: 30,
  };

  @Watch('assignedContacts')
  onAssignedContactChange(contacts: number[]): void {
    this.selected = contacts;
  }

  @Watch('search')
  onSearchTextChanged(term: string): void {
    this.handleOnSearchTermChange(term);
  }
  handleOnSearchTermChange = debounce(this.searchText, 1000);
  searchText(text: string): void {
    this.$store.dispatch('contact/searchContacts', {
      query: text,
      page: 1,
      limit: 30,
      isPaginated: true,
    });
  }

  loadMore(): void {
    if (this.search) {
      let payload = {
        query: this.search,
        ...this.options,
        page:
          Number(this.options?.pages ?? 0) > Number(this.options?.page)
            ? Number(this.options?.page) + 1
            : Number(this.options?.page),
        isPaginated: true,
      };
      this.$store.dispatch('contact/searchContacts', payload);
      return;
    }
    this.$store.dispatch('contact/loadMore', this.options);
  }

  @Watch('paginate')
  onPaginationChange(payload: IPaginate): void {
    this.options = { ...payload };
  }

  @Emit('addContacts')
  addContactsToGroup(): IContact[] | number[] {
    return this.selected;
  }

  @Emit('actions')
  close(): PayloadState {
    this.search = '';
    return {
      idx: 'contacts',
      state: false,
    };
  }
}
